import { Fancybox } from "@js/parts/fancybox.js"

// Make Fancybox  publicly available
window.Fancybox = Fancybox;

// Configure Fancybox
Fancybox.bind("[data-fancybox]", {
  Thumbs: {
	autoStart: false,
  },
});
// For the on Doc ready: Define a convenience method and use it
let ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
}


ready(() => { 
  /* Do things after DOM has fully loaded */ 
	
	
	
	//--------------------------------------------------------------- Navigation

	
	const toggleMenu = document.querySelector('.main-menu-toggle');
	const navigation = document.querySelector('.navigation');
	const menu = document.querySelector('.navigation ul');
	
	// check that the toggleMenu element exists before we attempt to attach the event listener
	if(typeof(toggleMenu) != 'undefined' && toggleMenu != null){
		
		/* When you click on the button, we call a function that will get the current value of the "aria-expanded" attribute, and invert it. It will do the same for the "hidden" attribute on the menu. When the menu is visible, we'll focus the first link so it gets properly announced. */
		
		toggleMenu.addEventListener('click', function () {
			
			// The JSON.parse function helps us convert the attribute from a string to a real boolean
			const open = JSON.parse(toggleMenu.getAttribute('aria-expanded'));
			
			// whatever the value of open is set it to the opposite
			toggleMenu.setAttribute('aria-expanded', !open);
			
			// whatever the value of the html element 'hidden' on menu is set it to the opposite
			menu.hidden = !menu.hidden;
			
			navigation.classList.toggle("navigation-active-mobile");
			toggleMenu.classList.toggle("is-active");
			
			// if the menu isn't hidden focus on the first menu link
			if (!menu.hidden) {
				menu.querySelector('a').focus();
			}
			
		});
		
	}

	
	
	// Drop down menus on navigation
	
	var menuItems = document.querySelectorAll('li.has-submenu');
	
	Array.prototype.forEach.call(menuItems, function(el, i){
		var activatingA = el.querySelector('a');
		var btn = '<button class="submenu-button-icon"><span><span class="visuallyhidden">show submenu for “' + activatingA.text + '”</span></span></button>';
		activatingA.insertAdjacentHTML('afterend', btn);
	
		el.querySelector('button').addEventListener("click",  function(event){
			if (this.parentNode.className == "has-submenu") {
				this.parentNode.className = "has-submenu open";
				this.parentNode.querySelector('a').setAttribute('aria-expanded', "true");
				this.parentNode.querySelector('button').setAttribute('aria-expanded', "true");
			} else {
				this.parentNode.className = "has-submenu";
				this.parentNode.querySelector('a').setAttribute('aria-expanded', "false");
				this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");
			}
			event.preventDefault();
		});
	});
	
	
	
	//--------------------------------------------------------------- Sticky header
	
	const siteBody = document.body;
	
	// Debounce limits how often the scroll function fires
	function debounce(method, delay) {
		clearTimeout(method._tId);
		method._tId= setTimeout(function(){
			method();
		}, delay);
	}
	
	window.onscroll = function() {
		
		debounce(scrollFunction, 100);
		
	};
	
	let oldScrollValue = 0;
	let newScrollValue = 0;
	let headerScrollDistance = 120;
	
	
	
	function scrollFunction() {
		
		newScrollValue = window.pageYOffset;
		
		if (oldScrollValue < newScrollValue) {
			
			// Scrolling Down
			
			if (document.body.scrollTop > headerScrollDistance || document.documentElement.scrollTop > headerScrollDistance) {
				
				// Scroll is greater than headerScrollDistance
				siteBody.classList.add("site-header-fixed");
				siteBody.classList.remove("site-header-fixed-visible");
			
			} else {
			  
				  // Scroll is less than headerScrollDistance
				  siteBody.classList.remove("site-header-fixed");
				  siteBody.classList.remove("site-header-fixed-visible");
			
			}
					
			
		} else if (oldScrollValue > newScrollValue) {
			
			// Scrolling Up
			
			if (document.body.scrollTop > headerScrollDistance || document.documentElement.scrollTop > headerScrollDistance) {
				
				// Scroll is greater than headerScrollDistance
				// Scroll is less than headerScrollDistance
				siteBody.classList.add("site-header-fixed");
				siteBody.classList.add("site-header-fixed-visible");
				
			
			} else {
			  
				// Scroll is less than headerScrollDistance
				siteBody.classList.remove("site-header-fixed");
				siteBody.classList.remove("site-header-fixed-visible");
			
			}
			
			
			
		}
		
		oldScrollValue = newScrollValue;
		  
		
	}
	
	
  
	// -------------------------------------------------------- Accessible modals
	
	
	// Open modals
	document.querySelectorAll('.modal-trigger').forEach(item => {
	
		item.addEventListener('click', event => {
		
			let targetLink = item.getAttribute('data-modal');
			
			let targetModal = document.getElementById(targetLink);
			
			openModal(targetModal);
			
			return false;
		
		})
	
	})
  
	  
	function openModal(targetModal) {
	
		targetModal.classList.add("modal-visible");
	
	}
	  
	  // Close modals
	  
	function closeModals() {
	
		let targetModals = document.querySelectorAll('.modal');
		
		targetModals.forEach(function(el) {
			  el.classList.remove("modal-visible");
		});
		
		
	
	}
	
	document.querySelectorAll('.modal-close-trigger').forEach(item => {
	
	item.addEventListener('click', event => {
	  
		  closeModals();
	  
		  return false;
	  
	})
	
	})
	
	// close modals with esc key
	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			  closeModals();
		}
	})
	
	
}); // END DOC READY